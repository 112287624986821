import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import authClient from '../Auth';

function NavBar(props) {
  
  const signOut = () => {
    authClient.signOut();
    props.history.replace('/');
  };

  return (
    <nav className="navbar navbar-dark bg-primary fixed-top">
      <Link className="navbar-brand" to="/">Home</Link>
      {
        authClient.loggedIn() &&
        <Link className="navbar-brand" to="/rooms">Szobák</Link>
      }
      {
        authClient.loggedIn() &&
        <Link className="navbar-brand" to="/calendar">Naptár</Link>
      }
      {
        !authClient.loggedIn() &&
        <button className="btn btn-dark" onClick={() => { authClient.signIn(props) }}>Sign In</button>
      }
      {
        authClient.loggedIn() &&
        <div>
          <label className="mr-2 text-white">{authClient.getProfile().name}</label>
          <button className="btn btn-dark" onClick={() => { signOut() }}>Sign Out</button>
        </div>
      }
    </nav>
  );
}

export default withRouter(NavBar);