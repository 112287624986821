import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import authClient from '../Auth';
import { DateList } from '../Components/DateViews';

class Calendar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      data: [],
      page: 0,
      pages: 0,
      count: 0,
      limit: 0
    };
    
    this.loadData = this.loadData.bind(this);

  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const result = (await axios.get(
      `https://hostel.api.idevelopment.hu/dates/${this.state.page}`,
      { headers: { 'Authorization': 'JWT ' + authClient.getToken() } }
    )).data;
    this.setState({
      isLoaded: true,
      data: result.result,
      count: result.count,
      pages: result.pages,
      limit: result.limit
    });
  }


  render() {
    return (
      <div className="container">
        {!this.state.isLoaded ? (
          <div className="spinner-border text-info" role="status">
            <span className="sr-only">Betöltés...</span>
          </div>
        ) : (
            <DateList data={this.state.data} />
          )}
      </div>
    )
  }
}

export default withRouter(Calendar);