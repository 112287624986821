import React, { Component } from 'react';
import {Route} from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import Home from './Home/Home';
import Login from './Login/Login';

import SecuredRoute from './SecuredRoute/SecuredRoute';
import Calendar from './Calendar/Calendar';
import Rooms from './Rooms/Rooms';
import Room from './Rooms/Room';


class App extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <Route exact path='/' component={Home}/>
        <Route exact path='/login' component={Login}/>
        <SecuredRoute path='/calendar' component={Calendar} />
        <SecuredRoute path='/rooms' component={Rooms} />
        <SecuredRoute path='/room/:roomId' component={Room} />
      </div>
    );
  }
}

export default App;
