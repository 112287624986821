import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Dialog from './Dialog';
import { Form, Field } from 'react-final-form'


export class RoomList extends Component {

    render() {
        return (
            <div className="col">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Név</th>
                            <th scope="col">Leírás</th>
                            <th scope="col" className="text-right">Ágyak száma</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.rooms.map(room => (
                            <tr key={room.id}>
                                <td><Link to={`/room/${room.id}`}>{room.name}</Link></td>
                                <td>{room.description}</td>
                                <td className="text-right">{room.bedsCount}</td>
                                <td className="text-right">
                                    <button type="button" className="btn btn-outline-danger btn-sm" onClick={e => {
                                        e.target.blur();
                                        if (window.confirm("Valóban törölni szerenéd ezt a szobát?"))
                                            this.props.target.handleFormDelete(e);
                                    }
                                    } value={room.id}>Törlés</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Dialog buttonTitle="Hozzáadás"
                    validate={(e) => {
                        if (e.roomName !== undefined && e.roomName.length > 0)
                            return {};
                        else
                            return { roomName: "Kötelező" };
                    }}>
                    <RoomDetailsForm 
                        onSubmit={this.props.add}
                        validate={this.props.validate}
                    />
                </Dialog>

            </div>
        )
    }
}

export class RoomDetails extends Component {

    render() {
        if (this.props.editMode) {
            return (
                <RoomDetailsForm
                    name={this.props.data.name}
                    description={this.props.data.description}
                    id={this.props.data.id}
                    close={this.props.close}
                    onSubmit={this.props.update}
                />
            );
        }
        else {
            return (
                <RoomDetailsView
                    name={this.props.data.name}
                    description={this.props.data.description}
                    goEditMode={this.props.goEditMode}
                />
            );
        }
    }
}

export class RoomDetailsView extends Component {

    render() {
        return (
            <div className="col">
                <h1>{this.props.name}</h1>
                <p>{this.props.description}</p>
                <button className="btn btn-primary" onClick={this.props.goEditMode}>Szerkesztés</button>
            </div>
        )
    }
}

export class RoomDetailsForm extends Component {
    render() {
        const initialValues = { id: this.props.id, name: this.props.name, description: this.props.description }
        return (
            <div className="col">
                <Form
                    onSubmit={(e) => { this.props.onSubmit(e); this.props.close(e); }}
                    validate={this.props.validate}
                    initialValues={initialValues}
                    render={({ handleSubmit, pristine, invalid, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="roomName">Név</label>
                                <Field autoFocus className="form-control" id="roomName" name="name" component="input" placeholder="" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="roomDescription">Leírás</label>
                                <Field className="form-control" id="roomDescription" name="description" component="textarea" placeholder="" />
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={pristine || invalid}>Mentés</button>
                            <button type="button" className="btn btn-link" onClick={this.props.close}>Mégsem</button>

                        </form>
                    )}
                />
            </div>
        );

    }
}