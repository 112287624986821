import React, { Component } from 'react';
import Popup from 'reactjs-popup';

class Dialog extends Component {

  constructor(props) {
    super(props);
    //this.target = this.props.target;

    this.state = { open: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <button className="btn btn-primary" onClick={this.openModal}>
          {this.props.buttonTitle}
        </button>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div>
            {React.cloneElement(this.props.children, { close: this.closeModal})}
          </div>
        </Popup>
      </div>
    )
  }


  /*render() {
    return (
      <div>
        <button className="btn btn-primary" onClick={this.openModal}>
          {this.props.buttonTitle}
        </button>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div>
            <Form
              onSubmit={this.onSubmit}
              validate={this.props.validate}
              render={({ handleSubmit, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>

                  {this.props.children}

                </form>
              )}
            />
          </div>
        </Popup>
      </div>
    )
  }*/
}

/**
                   <button type="submit" className="btn btn-primary" disabled={pristine || invalid}>Mentés</button>
                  <button type="button" className="btn btn-link" onClick={this.closeModal}>Mégsem</button>
 
 */

export default Dialog;