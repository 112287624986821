import React, { Component } from 'react';
//import './Login.css';
import authClient from '../Auth';

class Login extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    /*componentWillMount(){
        if(authClient.loggedIn())
            this.props.history.replace('/');
    }*/


    render() {
        return (
            <div style={{ width: '400px' }}>
                <form onSubmit={this.handleFormSubmit}>
                    <div className="form-group">
                        <label htmlFor="email_txt">Email address</label>
                        <input
                            id="email_txt"
                            className="form-control"
                            placeholder="Username"
                            name="username"
                            type="text"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password_txt">Password</label>
                        <input
                            id="password_txt"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            type="password"
                            onChange={this.handleChange}
                        />
                    </div>
                    <input
                        className="btn btn-primary mb-2"
                        value="SUBMIT"
                        type="submit"
                    />
                </form>

            </div>
        );
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    handleFormSubmit(e) {
        e.preventDefault();
        authClient.login(this.state.username, this.state.password)
            .then(res => {
                this.props.history.replace('/');
            })
            .catch(err => {
                alert(err);
            })
    }

}

export default Login;
