import React, {Component} from 'react';

class Home extends Component {
  /*constructor(props) {
    super(props);

    this.state = {
      questions: null,
    };
  }*/

  async componentDidMount() {
    /*const questions = (await axios.get('http://localhost:8081/')).data;
    this.setState({
      questions,
    });*/
  }

  render() {
    return (
      <div className="container">
        <div className="row">
        <p>Home</p>
        </div>
      </div>
    )
  }
}

export default Home;