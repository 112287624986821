import React, { Component } from 'react';

export class DateList extends Component {

    render() {
        return (
            <div className="col">
                <table className="table">
                    <tbody>
                        {this.props.data.map(date => (
                            <tr key={date.id}>
                                <td>{date.date}</td>
                                <td>{date.DateType}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}