import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import authClient from '../Auth';
//import Dialog from '../Components/Dialog';
//import { Field } from 'react-final-form';
import { RoomList } from '../Components/RoomViews';

class Rooms extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rooms: null,
    };

    this.handleFormAdd = this.handleFormAdd.bind(this);
    this.handleFormDelete = this.handleFormDelete.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const rooms = (await axios.get(
      'https://hostel.api.idevelopment.hu/rooms',
      { headers: { 'Authorization': 'JWT ' + authClient.getToken() } }
    )).data;
    this.setState({
      rooms,
    });
  }

  handleFormAdd(e) {
    axios.post(
      `https://hostel.api.idevelopment.hu/rooms`,
      {
        name: e.name,
        description: e.description,
      },
      {
        headers: { 'Authorization': 'JWT ' + authClient.getToken() },
      }
    ).then(response => {
      this.loadData();
    });
  }

  handleFormDelete(e) {
    axios.delete(
      `https://hostel.api.idevelopment.hu/rooms/${e.target.value}`,
      {
        headers: { 'Authorization': 'JWT ' + authClient.getToken() },
      }
    ).then(response => {
      this.loadData();
    });
  }

  validateForm(e) {
    if (e.name !== undefined && e.name.length > 0)
      return {};
    else
      return { roomName: "Kötelező" };
  }


  render() {
    return (
      <div className="container">
        <div className="row">
          <h1>Szobák</h1>
        </div>
        {this.state.rooms === null ? (
          <div className="spinner-border text-info" role="status">
            <span className="sr-only">Betöltés...</span>
          </div>) : (
            <RoomList
              rooms={this.state.rooms}
              delete={this.handleFormDelete}
              add={this.handleFormAdd}
              validate={this.validateForm}
              target={this}
            />
          )}
      </div>
    )
  }
}

/*function RoomsTable(props) {
  const rooms = props.rooms;

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Név</th>
            <th scope="col">Leírás</th>
            <th scope="col" className="text-right">Ágyak száma</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rooms.map(room => (
            <tr key={room.id}>
              <td><Link to={`/room/${room.id}`}>{room.name}</Link></td>
              <td><Link to={`/room/${room.id}`}>{room.description}</Link></td>
              <td className="text-right"><Link to={`/room/${room.id}`}>{room.bedsCount}</Link></td>
              <td className="text-right">
                <button type="button" className="btn btn-outline-danger btn-sm" onClick={e => {
                  e.target.blur();
                  if (window.confirm("Valóban törölni szerenéd ezt a szobát?"))
                    props.target.handleFormDelete(e);
                }
                } value={room.id}>Törlés</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <Dialog buttonTitle="Hozzáadás" target={props.target}
        validate={(e) => {
          if (e.roomName !== undefined && e.roomName.length > 0)
            return {};
          else
            return { roomName: "Kötelező" };
        }}>
        <NewRoomDialogContent target={props.target} />
      </Dialog>

    </div>

  );
}*/

/*function NewRoomDialogContent(props) {

  return (
    <div>
      <h3>Új szoba</h3>
      <div className="form-group">
        <label htmlFor="roomName">Név</label>
        <Field autoFocus className="form-control" id="roomName" name="roomName" component="input" placeholder="" />
        <label htmlFor="roomDescription">Leírás</label>
        <Field className="form-control" id="roomDescription" name="roomDescription" component="textarea" placeholder="" />
      </div>
    </div>
  );
}*/

export default withRouter(Rooms);