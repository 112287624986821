import React, { Component } from 'react';
import { withRouter/*, Link*/ } from 'react-router-dom';
import axios from 'axios';
import authClient from '../Auth';
import { RoomDetails } from '../Components/RoomViews';
import { BedList } from '../Components/BedViews';
//import { isUndefined } from 'util';

class Room extends Component {

  constructor(props) {
    super(props);

    this.state = {
      room: null,
      editMode: false
    };

    this.handleNewBed = this.handleNewBed.bind(this);
    this.handleFormUpdate = this.handleFormUpdate.bind(this);
    this.handleDeleteBed = this.handleDeleteBed.bind(this);
    this.loadData = this.loadData.bind(this);
    this.goEditMode = this.goEditMode.bind(this);
    this.goViewMode = this.goViewMode.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { match: { params } } = this.props;
    const room = (await axios.get(
      `https://hostel.api.idevelopment.hu/rooms/${params.roomId}`,
      { headers: { 'Authorization': 'JWT ' + authClient.getToken() } }
    )).data;
    this.setState({
      room: room
    });
  }

  goEditMode(e) {
    this.setState({
      editMode: true
    });
  }

  goViewMode(e) {
    this.setState({
      editMode: false
    });
  }

  handleFormUpdate(e) {
    axios.put(
      `https://hostel.api.idevelopment.hu/rooms/${e.id}`,
      {
        name: e.name,
        description: e.description
      },
      {
        headers: { 'Authorization': 'JWT ' + authClient.getToken() },
      }
    ).then(response => {
      this.loadData();
      //console.log(response);
    });
  }

  handleNewBed(e) {
    axios.post(
      `https://hostel.api.idevelopment.hu/beds`,
      {
        name: e.name,
        room_id: this.state.room[0].id
      },
      {
        headers: { 'Authorization': 'JWT ' + authClient.getToken() },
      }
    ).then(response => {
      this.loadData();
      //console.log(response);
    });
  }

  handleDeleteBed(e) {
    axios.delete(
      `https://hostel.api.idevelopment.hu/beds/${e.target.value}`,
      {
        headers: { 'Authorization': 'JWT ' + authClient.getToken() },
      }
    ).then(response => {
      this.loadData();
      //console.log(response);
    });
  }

  render() {
    return (
      <div className="container">
        {this.state.room === null ? (
          <div className="spinner-border text-info" role="status">
            <span className="sr-only">Betöltés...</span>
          </div>
        ) : (
            <div className="row">
              <RoomDetails
                data={this.state.room[0]}
                update={this.handleFormUpdate}
                close={this.goViewMode}
                target={this}
                editMode={this.state.editMode}
                goEditMode={this.goEditMode}
              />
              <BedList
                data={this.state.room[0].Beds}
                new={this.handleNewBed}
                delete={this.handleDeleteBed}
              />
            </div>
          )}
      </div>
    );
  }

}



export default withRouter(Room);