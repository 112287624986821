import React, { Component } from 'react';
//import { withRouter } from 'react-router-dom';
import Dialog from './Dialog';
import { Form, Field } from 'react-final-form'

export class BedList extends Component {

  render() {
    return (
      <div className="col">
        <h2>Ágyak</h2>

        <table className="table">
          <tbody>
            {this.props.data.map(bed => (
              <tr key={bed.id}>
                <td>{bed.name}</td>
                <td className="text-right">
                  <button type="button" className="btn btn-outline-danger btn-sm" onClick={e => {
                    e.target.blur();
                    if (window.confirm("Valóban törölni szerenéd ezt az ágyat?"))
                      this.props.delete(e);
                  }
                  } value={bed.id}>Törlés</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Dialog buttonTitle="Hozzáadás">
          <BedForm  onSubmit={this.props.new}/>
        </Dialog>
      </div>
    )
  }
}

function BedForm(props) {

    return (
        <Form
            onSubmit={(e) => { props.onSubmit(e); props.close(e); }}
            validate={props.validate}
            initialValues={props.initialValues}
            render={({ handleSubmit, pristine, invalid, values }) => (
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="bedName">Név</label>
                        <Field autoFocus className="form-control" id="bedName" name="name" component="input" placeholder="" />
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={pristine || invalid}>Mentés</button>
                    <button type="button" className="btn btn-link" onClick={props.close}>Mégsem</button>

                </form>
            )}
        />
    );
}


/*function NewBedDialogContent(props) {

  return (
    <div>
      <h3>Új ágy</h3>
      <div className="form-group">
        <label htmlFor="bedName">Név</label>
        <Field autoFocus className="form-control" id="bedName" name="bedName" component="input" placeholder="" />
      </div>
    </div>
  );
}*/


